import { Dispatch, SetStateAction, useCallback, useRef } from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { AssetList } from '@/components/AssetList/AssetList';
import { Asset, CustomChain } from '@/types/Asset';
import { Network } from '@/types/Network';
import { formatCryptoBalance, usdFormatter } from '@/utils/formatter/balance';
import { useConversionRate } from '@/utils/hooks/useConversionRate';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { useFindChainByNetwork } from '@/utils/hooks/useFindNetwork';
import useMediaQuery from '@/utils/hooks/useMediaQuery';
import { networkToLayer } from '@/utils/networks/networkToLayer';
import { parseUnits } from 'viem';
import Image from 'next/image';

type BridgeInputProps = {
  inputNetwork: Network;
  outputNetwork: Network;
  balance: string;
  amount: string;
  assets: Asset[];
  selectedAsset: Asset;
  setAmount: Dispatch<SetStateAction<string>>;
  setSelectedAsset: Dispatch<SetStateAction<Asset>>;
  isWithdraw: boolean;
  children?: JSX.Element;
};

export function BridgeInput({
  inputNetwork,
  outputNetwork,
  balance,
  amount,
  setAmount,
  assets,
  selectedAsset,
  setSelectedAsset,
  isWithdraw,
  children,
}: BridgeInputProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const input = useRef<HTMLInputElement>(null);
  const isDesktop = useMediaQuery('(min-width: 640px)');
  const inputChain = useFindChainByNetwork({ network: inputNetwork }) as CustomChain;
  const outputChain = useFindChainByNetwork({ network: outputNetwork }) as CustomChain;
  const conversionRateData = useConversionRate({
    asset: selectedAsset.apiId,
  });
  const conversionRate =
    conversionRateData && amount ? usdFormatter(conversionRateData * +amount) : '$0.00';

  function handleChangeAmount(e: { target: { value: SetStateAction<string> } }) {
    const value = parseFloat(e.target.value.toString());
    const digits = [...(e.target.value.toString() || '0')];
    // only allow the following `-`,`0,`1`.....`9`
    const allowedChars = new Set([
      '46',
      '48',
      '49',
      '50',
      '51',
      '52',
      '53',
      '54',
      '55',
      '56',
      '57',
    ]);
    const filteredNonDigits = digits.find((d) => {
      const code = d.charCodeAt(0);
      if (!allowedChars.has(code.toString())) return false;
      return true;
    });
    // the input contains any not allowed ch
    if (!filteredNonDigits) return;
    // contains double `.`
    if (e.target.value.toString().split('.').length > 2) return;
    // less than 0
    if (value < 0) return;
    // long text
    if (e.target.value.length > 12) return;

    setAmount(e.target.value);
  }

  const handleChangeAsset = useCallback(
    (asset: Asset) => {
      return () => {
        onClose();
        setSelectedAsset(asset);
        setAmount('');
      };
    },
    [onClose, setAmount, setSelectedAsset],
  );

  function setMaxBalance() {
    setAmount((parseFloat(balance) * 0.99).toFixed(6).toString());
  }

  const error =
    amount !== '' &&
    balance !== '' &&
    parseUnits(amount, 18) > parseUnits(balance, 18) &&
    'Insufficient balance';

  function handleFocus() {
    if (amount === '0') setAmount('');
    input.current?.focus();
  }

  const networkLayer = networkToLayer(inputNetwork);
  const selectedAssetSymbol =
    networkLayer === 'L1' ? selectedAsset.L1symbol : selectedAsset.L2symbol;

  return (
    <div className="flex w-full flex-col p-6">
      <div className="mb-6 flex items-center gap-6">
        <div className="flex flex-col gap-3">
          <div className="font-mono text-xs uppercase leading-5 text-white/50">From...</div>
          <div className="flex flex-row items-center justify-center gap-3 border border-white/20 p-4">
            <Image src={inputChain?.svg} width={24} height={24} alt={inputChain?.name} />
            <div className="font-mono text-sm">{inputChain?.name}</div>
          </div>
        </div>

        <ArrowRightIcon className="mt-8 h-4 w-4 text-white" />

        <div className="flex flex-col gap-3">
          <div className="font-mono text-xs uppercase leading-5 text-white/50">To...</div>
          <div className="flex flex-row items-center justify-center gap-3 border border-white/20 p-4">
            <Image src={outputChain?.svg} width={24} height={24} alt={outputChain?.name} />
            <div className="font-mono text-sm">{outputChain?.name}</div>
          </div>
        </div>
      </div>
      <span className="mb-4 font-mono text-xs uppercase leading-5 text-white/50">AMOUNT</span>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className="flex max-w-[100vw] cursor-text flex-row items-center justify-between"
        onClick={handleFocus}
      >
        <input
          className="bg-transparent font-mono text-4xl outline-none max-[640px]:grow sm:text-6xl"
          maxLength={9}
          type="number"
          key="amountInput"
          ref={input}
          pattern="[0-9]*"
          inputMode="decimal"
          style={
            isDesktop
              ? {
                  minWidth: 40,
                  width: amount.length * 37 + 20,
                  maxWidth: 16 * 31,
                }
              : {
                  maxWidth: 'calc(100vw - 120px)',
                }
          }
          placeholder="0"
          min="0"
          value={amount}
          onChange={handleChangeAmount}
        />
        <div className="relative flex w-fit flex-row border border-white/20 bg-white/10 px-3 py-4">
          <AssetList
            isOpen={isOpen}
            onClose={onClose}
            isWithdraw={isWithdraw}
            onOpen={onOpen}
            assets={assets}
            selectedAsset={selectedAsset}
            network={inputNetwork}
            handleChangeAsset={handleChangeAsset}
          />
        </div>
      </div>
      <div className="mt-4 h-[1px] w-[100%] shrink-0 bg-white/10" />
      {error && (
        <div className="flex flex-row space-x-0.5 text-sm text-stone-600">
          <span className="font-mono text-sm text-primary">{error}</span>
        </div>
      )}
      <div className="mt-6 flex flex-row items-center justify-between">
        <div className="flex w-full flex-row place-content-between pr-3">
          <span className="font-mono text-sm font-normal text-white/50">{conversionRate} USD</span>
          <span className="font-mono text-sm font-normal text-white/50">
            {formatCryptoBalance(balance)} {selectedAssetSymbol} available
          </span>
        </div>
        <div>
          <button
            onClick={setMaxBalance}
            className="flex flex-row items-center justify-center rounded p-2 font-mono text-sm text-primary"
            type="button"
          >
            MAX
          </button>
        </div>
      </div>
      <div className="hidden w-64 flex-row pt-9 sm:block">{children}</div>
    </div>
  );
}
