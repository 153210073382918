import { Asset, CustomChain } from '@/types/Asset';
import { Network } from '@/types/Network';
import { formatCryptoBalance, usdFormatter } from '@/utils/formatter/balance';
import { useConversionRate } from '@/utils/hooks/useConversionRate';
import { useFindChainByNetwork } from '@/utils/hooks/useFindNetwork';
import { networkToLayer } from '@/utils/networks/networkToLayer';
import getConfig from 'next/config';
import { useFeeData } from 'wagmi';

const { publicRuntimeConfig } = getConfig();

type DepositSummaryProps = {
  outputNetwork: Network;
  balance: string;
  header: string;
  chainId: string;
  selectedAsset: Asset;
  isDeposit: boolean;
};

const challengeWindow = publicRuntimeConfig.l1ChainID === '1' ? '7 days' : '12 seconds';

export function TransactionSummary({
  balance,
  outputNetwork,
  header,
  chainId,
  selectedAsset,
  isDeposit,
}: DepositSummaryProps) {
  const outputChain = useFindChainByNetwork({ network: outputNetwork }) as CustomChain;
  const ethConversionRate = useConversionRate({ asset: selectedAsset.apiId });
  const { data: feeData } = useFeeData({
    formatUnits: 'ether',
    chainId: parseInt(chainId),
  });
  const gasPrice = parseFloat(feeData?.formatted?.gasPrice ?? '0');
  const cryptoBalance = balance && +balance > 0 ? formatCryptoBalance(balance) : 0;
  const transactionTotal = balance && +balance > 0 ? gasPrice + +balance : 0;
  const fiatBalance =
    ethConversionRate && +balance > 0 ? usdFormatter(ethConversionRate * +balance) : '$0';

  const networkLayer = networkToLayer(outputNetwork);
  const selectedAssetSymbol =
    networkLayer === 'L1' ? selectedAsset.L1symbol : selectedAsset.L2symbol;

  return (
    <div className="flex flex-col border-b border-white/10 pb-8 pt-2 font-mono">
      <div className="p-4 font-mono text-sm text-white/50">{header}</div>
      <div className="relative flex w-full flex-row justify-between p-6 pb-0">
        <div className="has-tooltip">
          {selectedAsset.L1symbol === 'USDC' && (
            <a
              href="https://redstone.xyz/docs/what-is-redstone"
              target="_blank"
              rel="noreferrer noopener"
              className="tooltip -mt-10 ml-6 rounded-lg bg-cds-background-gray-90 p-2 text-black underline shadow-lg"
            >
              Learn more
            </a>
          )}
          <div className="flex grow flex-col">
            <span className="">Receive {selectedAssetSymbol}</span>
            <p className="mr-3 text-sm text-white/50">On {outputChain.summary.location}</p>
          </div>
        </div>
        <div className="text-right">
          {cryptoBalance} {selectedAssetSymbol}
          <p className="text-sm text-white/50">{fiatBalance}</p>
        </div>
      </div>
      <div className="flex w-full flex-row justify-between p-6 pb-0">
        <div className="flex grow flex-col">
          <span className="">Transfer time</span>
          <p className="mr-3 text-sm text-white/50">
            {isDeposit ? '' : `Withdrawals take about ${challengeWindow} to finalize on Redstone`}
          </p>
        </div>
        <p className="text-right">{isDeposit ? 'A few minutes' : `About ${challengeWindow}`}</p>
      </div>
      {/* <div className="flex w-full flex-row justify-between p-6 pb-0">
        <div className="flex grow flex-col">
          <span className="">Network fee </span>
          <p className="mr-3 text-sm text-white/50">Estimated</p>
        </div>
        <div className="text-right">
          <span>{gasPrice.toFixed(9)} ETH</span>
          <p className="text-white/50">
            {ethConversionRate ? usdFormatter(gasPrice * ethConversionRate, 6) : '$0'} USD
          </p>
        </div>
      </div> */}
      <div className="flex w-full flex-row justify-between p-6 pb-0">
        <div className="flex grow flex-col">
          <span className="">Total</span>
          <p className="mr-3 text-sm text-white/50">with est. fees</p>
        </div>
        <div className="text-right">
          <span>{transactionTotal.toFixed(9)} ETH</span>
          <p className="text-white/50">
            {ethConversionRate ? usdFormatter(transactionTotal * ethConversionRate, 6) : '$0'} USD
          </p>
        </div>
      </div>
    </div>
  );
}
